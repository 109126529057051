<template>
  <vx-card title="Import Excel">
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="territorySelected"
          :options="territoryData"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          track-by="code"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Sales Channel</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="salesChannel.selected"
          :options="salesChannel.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file"
          name="file"
          accept=".xls, .xlsx"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="ml-auto mt-2" v-on:click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="warning"
          v-on:click="handleDownload"
        >Download Template</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  data() {
    return {
      territoryData: [],
      territorySelected: null,
      uploadReady: true,
      salesChannel: {
        options: [],
        selected: null
      },
      salesChannelId: null
    };
  },
  methods: {
    getTerritoryData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code"
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "success") {
            this.territoryData = resp.data.records;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },
    handleDownload() {
      if (this.territorySelected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
      } else {
        this.$vs.loading();
        this.$http
          .get("/api/v1/sales-order/excel", {
            params: {
              territory_code: this.territorySelected.code
            },
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream"
            }
          })
          .then(resp => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "sales-order-template.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
          });
      }
    },
    handleSubmit() {
      var file = this.$refs.file.files[0];
      if (this.territorySelected == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
      } else if (this.salesChannelId == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "Sales Channel is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
      } else if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
      } else {
        this.formData = new FormData();
        this.formData.append("territory_code", this.territorySelected.code);
        this.formData.append("sales_channel_id", this.salesChannelId);
        this.formData.append("file", file);
        this.$http
          .post("/api/v1/sales-order/excel", this.formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(resp => {
            if (resp.status == "success") {
              var message = "";
              resp.data.map(function(item, index) {
                var total = resp.data.length;
                if (index == total - 1) {
                  message += item.sales_order_code;
                } else {
                  message += item.sales_order_code + ", ";
                }
              });
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
              this.uploadReady = false;
              this.$nextTick(() => {
                this.uploadReady = true;
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          });
      }
    },
    getAllDataSalesChannel() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/sales-channel", {
          params: {
            order: "name",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.salesChannel.options = resp.data.records;
            if (this.salesChannel.options.length > 0) {
              this.salesChannel.selected = this.salesChannel.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    }
  },
  mounted() {
    this.getTerritoryData();
    this.getAllDataSalesChannel();
  },
  watch: {
    "salesChannel.selected": function(v) {
      this.salesChannelId = v.id;
    }
  }
};
</script>
